import socketIOClient from 'socket.io-client';
import md5 from 'blueimp-md5';

import { localhostRoute } from './localhost-route'

const state = {
  endpoint: null,
  socket: null,
  connected: false,
  jwt: null,
  project: null,
  projectdata: null,
  values: null,
  hash: null
}

if (window.location.hostname === "localhost") {
  // return "http://localhost:4090";
  state.endpoint = localhostRoute().comms;
} else {
  const _hostname = window.location.hostname.replace('label', 'comms')
  state.endpoint = window.location.protocol + "//" + _hostname;
}

const socketio = {
  is_popped: function () {
    this.broadcast({type: "Close", value: true})
  },
  connected: function () {
    return state.connected
  },
  hash: function () {
    return state.hash
  },
  setupConnection: function (username) {
    return new Promise((resolve, reject) => {
      if (!state.connected && !state.socket) { 
        function getRandomBase64(length) {
          const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
          let result = '';
          for (let i = 0; i < length; i++) {
              const randomIndex = Math.floor(Math.random() * chars.length);
              result += chars[randomIndex];
          }
          return result;
      }
        const hashWithDate = (username) => {
          const currentDate = new Date().toISOString().slice(0, 10);
          const dataToHash = `${getRandomBase64(10)}${username}${currentDate}`;
          return md5(dataToHash).toString();
        };
        // const hash_ = md5(username, jwtToken);
        state.hash = hashWithDate(username)
        state.socket = socketIOClient(state.endpoint);

        console.log('state.hash', state.hash);
  
        state.socket.on('connect', () => {
          state.socket.emit('join-room', state.hash);
          console.log('Connected with socket ID:', state.socket.id, 'and hash:', state.hash);
          state.connected = true
          resolve(true)
        });
  
        /*
        state.socket.on('retrieve-jwt', (data) => {
          setJwt(data);
          console.log('JWT Retrieved:', data);
        });
  
        state.socket.on('retrieve-project', setProjectData);
        state.socket.on('project-selected', setProject);
        state.socket.on('broadcasted-values', (data) => {
          setValues(v => `${v ? `${v}, ` : ''}Received ${data} from the room.`);
        });
        */
      } else {
        console.log('Attempt to reconnect or already connected');
        resolve(false)
      }
    })
  },
  sendJwt: function (jwt) {
    if (state.connected && state.socket) {
      console.log('Connected, sending jwt', jwt)
      state.socket.emit('send-jwt', jwt);
      state.jwt = jwt
    } else {
      console.log('Not connected or no socket available to send.');
    }
  },
  selectProject: function (project) {
    if (state.connected && state.socket) {
      console.log('Connected, sending project', project)
      state.socket.emit('select-project', project);
      state.project = project
    } else {
      console.log('Not connected or no socket available to send.');
    }
  },
  broadcast: function (value) {
    if (state.connected && state.socket) {
      console.log('Connected, sending broadcast', value)
      state.socket.emit('broadcast', value);
      state.values = value
    } else {
      console.log('Not connected or no socket available to send.');
    }
  },
  aiBroadcastValues: function (values) {
    if (state.connected && state.socket) {
      console.log('Connected, sending ai broadcast', values)
      state.socket.emit('ai-broadcast', values);
      state.values = values
    } else {
      console.log('Not connected or no socket available to send.');
    }
  },
  registerBroadcastReceiver: function (callback) {
    if (state.connected && state.socket) {
      console.log('Registering broadcast');
      state.socket.on('broadcasted-values', (data) => {
        callback(data);
        console.log('data', data); // Moved inside the callback
      });
    } else {
      console.log('Not connected or no socket available to send.');
    }
  }
}

export default socketio;